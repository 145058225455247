.repeat-password {
  width: 100%;
  height: 5px;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  background: #fff;
  border-radius: 5px;
}

.repeat-password::-webkit-progress-bar {
  border-radius: 9px;
  background-color: #c6c6c6;
}

.repeatprogressdanger::-webkit-progress-value {
  width: 100%;
  background-color: #f25f5c;
  border-radius: 9px 0px 0px 9px;
}
.repeatprogresssuccess::-webkit-progress-value {
  background-color: #64dd17;
  width: 100%;
  border-radius: 9px;
}
