/* for inputdropdonw field */

.labelDropdown {
    @apply w-full text-sm font-tahoma-bold text-black mt-3 lg:mt-0 ml-1.5 font-medium
}
.wrapper {
    @apply flex justify-between items-center gap-x-4
}
.dropdownInput {
    @apply flex h-10 w-full items-center justify-center rounded-xl border border-gray-400  p-3 text-sm outline-none bg-AFPBGPrimary
}