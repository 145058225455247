.password {
  width: 100%;
  height: 5px;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  background: #fff;
  border-radius: 5px;
}

.password::before,
.password::after {
  content: "";
  z-index: 10;
  display: block;
  height: inherit;
  position: absolute;
  border-style: solid;
  border-color: #fff;
  border-width: 0 5px 0;
  width: calc(25% - 1px);
  background: transparent;
}
.password::before {
  left: calc(25%);
}
.password::after {
  right: calc(25%);
}
.password:nth-child(1)::after {
  border-left: 0;
}

.strength-1::-webkit-progress-value {
  background-color: #f25f5c;
  width: 100%;
}
.strength-2::-webkit-progress-value {
  background-color: #ffca28;
  width: 100%;
}
.strength-3::-webkit-progress-value {
  background-color: #64dd17;
  width: 100%;
}
.strength-4::-webkit-progress-value {
  background-color: #4bbb06;
  width: 100%;
}

.strength-1,
.strength-0s {
  display: flex;
  font-size: 11px;
  font-weight: 500;
  color: #f25f5c;
}
.strength-2 {
  display: flex;
  color: #ffca28;
  font-size: 11px;
  font-weight: 500;
}
.strength-3 {
  display: flex;
  font-size: 11px;
  color: #64dd17;
  font-weight: 500;
}
.strength-4 {
  display: flex;
  font-size: 11px;
  font-weight: 500;
  color: #4bbb06;
}
