.wrapper {
  @apply flex items-center flex-col gap-1;
}

.inputLabel {
  @apply text-sm font-tahoma-bold text-black ml-1.5 font-medium w-full md:w-[200px] lg:w-[260px] xl:w-[300px] 2xl:w-[340px];
}
.editableContainer {
  @apply flex justify-between items-center gap-x-4;
}
.input {
  @apply flex h-10 w-full items-center justify-center rounded-xl border border-gray-400 p-3 text-sm outline-none;
}
.textarea {
  @apply flex h-6 w-full items-center justify-center rounded-xl border border-gray-400 p-3 text-sm outline-none;
}
