.uploadInputContainer {
  @apply rounded-xl border border-dashed border-gray-400 w-full bg-white;
}

.uploadInput {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
