.sticky {
  position: fixed !important;
  top: 0 !important;
}

.inside {
  z-index: 1000 !important;
}

.step-label {
  bottom: 2.15rem;
  position: absolute;
  white-space: nowrap;
}

.active-color {
  color: #1c9d5b !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}

.isActive {
  color: #1c9d5b !important;
}

.lastIndex {
  color: red !important;
}

.stepperIcons {
  gap: 10px;
  display: "flex";
  position: relative;
  align-items: start;
  flex-direction: column-reverse;
}
.percentageWrapper {
  width: 100%;
  position: relative;
}
