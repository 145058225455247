.paymentSettingBtn {
      @apply flex justify-start items-center text-AFPPrimary gap-2 text-sm cursor-pointer;
    }
    
    .paymentIcon {
      @apply bg-AFPPrimary text-AFPBGPrimary w-[1rem] h-[1rem] rounded-full font-bold flex justify-center items-center;
    }
    
    .formLayout {
      @apply flex w-full flex-col gap-8 max-w-[680px];
    }
    