.searchDropdown .arrow {
  top: 14px;
  right: 10px;
  content: " ";
  display: block;
  position: absolute;
}

.searchDropdown .selected-value input {
  width: 100%;
  outline: none;
  cursor: default;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.searchDropdown .options {
  top: 100%;
  width: 100%;
  z-index: 1000;
  display: none;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.searchDropdown .options.open {
  display: block;
}

.searchDropdown .option {
  display: block;
  cursor: pointer;
  padding: 8px 10px;
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
}

.searchDropdown .option.selected,
.searchDropdown .option:hover {
  color: #333;
  background-color: #fbfbfb;
}
