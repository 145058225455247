.date-picker-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;
    width: 206px;
  }
  
  .calendar-icon {
    position: absolute;
    right: 10px;
    font-size: 24px;
    /* color: #333; */
    cursor: pointer;
    pointer-events: auto; /* Make sure the icon is clickable */
  }
  
  .date-picker-input {
    padding: 10px;
    padding-right: 35px; /* Add padding to ensure the text doesn't overlap with the icon */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 200px; /* Adjust the width as needed */
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container {
    width: 100%;
  }
  