.progressBar {
  width: 100%;
  height: 20px;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  background: #fff;
  border-radius: 12px;
}

.progressBar::-webkit-progress-bar {
  background-color: #c6c6c6;
  border-radius: 9px;
}

.barStrength-1::-webkit-progress-value {
  background-color: #f25f5c;
  width: 100%;
  border-radius: 9px 0px 0px 9px;
}
.barStrength-2::-webkit-progress-value {
  background-color: #a31d1a;
  width: 100%;
  border-radius: 9px 0px 0px 9px;
}
.barStrength-3::-webkit-progress-value {
  background-color: #ffca28;
  width: 100%;
  border-radius: 9px 0px 0px 9px;
}
.barStrength-4::-webkit-progress-value {
  background-color: #123abc;
  width: 100%;
  border-radius: 9px 0px 0px 9px;
}
.barStrength-5::-webkit-progress-value {
  background-color: #64dd17;
  width: 100%;
  border-radius: 9px;
}
