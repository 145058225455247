.navcontainer {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  transition: "all 1.5s";
}
.navLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  align-items: center;
  flex: 1;
  border-radius: 0.375rem;
  display: none;
}

@media (min-width: 768px) {
  .navcontainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    flex-wrap: nowrap;
  }
  .navLink {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
    position: relative;
    margin-top: 1rem;
    box-shadow: none;
  }
}

/* mobile menu */
.navMenu {
  width: 100%;
  left: -100%;
  z-index: 41;
  display: flex;
  position: fixed;
  transition: 850ms;
  min-height: 100vh;
  justify-content: center;
  background-color: transparent;
}
.navMenu.active {
  left: 0;
  transition: 350ms;
}

.blurbg {
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(10px);
  background-color: rgba(170, 165, 165, 0.5);
}
