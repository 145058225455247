.wrapper {
  @apply flex items-center flex-col gap-1;
}
.label {
  @apply text-sm font-tahoma-bold text-black ml-1.5 font-medium w-full;
}
.container {
  @apply w-full flex flex-col gap-2;
}
.inputContainer {
  @apply flex h-10 w-full items-center justify-center rounded-xl border text-sm outline-none z-20 bg-white pl-3 pr-1 py-0;
}
.input {
  @apply flex h-full w-full items-center justify-center rounded-xl  text-sm outline-none z-20 bg-[#fffff] py-3;
}
.info {
  @apply flex justify-start items-center gap-2 font-bold text-[#1C1A1A] text-sm font-tahoma-bold;
}
